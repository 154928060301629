var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left"},[_c('span',{staticClass:"form-title"},[_vm._v("Informações Gerais")]),_c('div',{staticClass:"row"},[_c('ed-input-file',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"name":"strArquivo","label":"Selecione o certificado","rules":"required","required":"","accept":"*.pfx"},model:{value:(_vm.formData.arquivo),callback:function ($$v) {_vm.$set(_vm.formData, "arquivo", $$v)},expression:"formData.arquivo"}}),_c('ed-input-text',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"name":"strSenha","label":"Senha do Certificado","type":"password","rules":!_vm.formData.intId ? 'required' : ''},model:{value:(_vm.formData.strSenha),callback:function ($$v) {_vm.$set(_vm.formData, "strSenha", $$v)},expression:"formData.strSenha"}}),(!_vm.formData.intId || (_vm.formData.arquivo && _vm.formData.arquivo.strConteudo))?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 ma-0 text-center mt-4 mb-4"},[_c('ed-button',{attrs:{"label":"Validar Certificado","iconLeft":_vm.$utilidade.getIcone('checkbox'),"color":"error"},on:{"click":_vm.validarCertificado}})],1):_vm._e(),(_vm.formData.strNome)?_c('ed-input-text',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",attrs:{"name":"strNome","label":"Nome","rules":"required","readonly":"","required":""},model:{value:(_vm.formData.strNome),callback:function ($$v) {_vm.$set(_vm.formData, "strNome", $$v)},expression:"formData.strNome"}}):_vm._e(),(_vm.formData.strNome)?_c('ed-input-text',{directives:[{name:"mask",rawName:"v-mask",value:(
            _vm.$utilidade.onlyNumber(_vm.formData.strDocumento).toString().length <= 11
              ? '999.999.999-99'
              : '99.999.999/9999-99'
          ),expression:"\n            $utilidade.onlyNumber(formData.strDocumento).toString().length <= 11\n              ? '999.999.999-99'\n              : '99.999.999/9999-99'\n          "}],staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"name":"strDocumento","label":"Documento","rules":'required' +
            (_vm.$utilidade.onlyNumber(_vm.formData.strDocumento).toString().length <= 11
              ? 'cpf'
              : 'cnpj'),"required":"","readonly":""},model:{value:(_vm.formData.strDocumento),callback:function ($$v) {_vm.$set(_vm.formData, "strDocumento", $$v)},expression:"formData.strDocumento"}}):_vm._e(),(_vm.formData.strNome)?_c('ed-input-text',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"name":"strDataValidade","label":"Data de Validade","rules":"required","required":"","readonly":""},model:{value:(_vm.formData.strDataValidade),callback:function ($$v) {_vm.$set(_vm.formData, "strDataValidade", $$v)},expression:"formData.strDataValidade"}}):_vm._e(),(_vm.formData.strNome)?_c('ed-input-switch',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"name":"boolAtivo","label":"Certificado Ativo?"},model:{value:(_vm.formData.boolAtivo),callback:function ($$v) {_vm.$set(_vm.formData, "boolAtivo", $$v)},expression:"formData.boolAtivo"}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }