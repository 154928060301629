<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Gerais</span>

        <div class="row">
          <ed-input-file
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.arquivo"
            name="strArquivo"
            label="Selecione o certificado"
            rules="required"
            required
            accept="*.pfx"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strSenha"
            name="strSenha"
            label="Senha do Certificado"
            type="password"
            :rules="!formData.intId ? 'required' : ''"
          />

          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 ma-0 text-center mt-4 mb-4"
            v-if="!formData.intId || (formData.arquivo && formData.arquivo.strConteudo)"
          >
            <ed-button
              label="Validar Certificado"
              @click="validarCertificado"
              :iconLeft="$utilidade.getIcone('checkbox')"
              color="error"
            />
          </div>

          <ed-input-text
            v-if="formData.strNome"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNome"
            name="strNome"
            label="Nome"
            rules="required"
            readonly
            required
          />

          <ed-input-text
            v-if="formData.strNome"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strDocumento"
            name="strDocumento"
            label="Documento"
            :rules="
              'required' +
              ($utilidade.onlyNumber(formData.strDocumento).toString().length <= 11
                ? 'cpf'
                : 'cnpj')
            "
            v-mask="
              $utilidade.onlyNumber(formData.strDocumento).toString().length <= 11
                ? '999.999.999-99'
                : '99.999.999/9999-99'
            "
            required
            readonly
          />

          <ed-input-text
            v-if="formData.strNome"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strDataValidade"
            name="strDataValidade"
            label="Data de Validade"
            rules="required"
            required
            readonly
          />

          <ed-input-switch
            v-if="formData.strNome"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Certificado Ativo?"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputFile,
  EdInputDate,
  EdInputSwitch,
  EdButton,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputFile,
    EdInputDate,
    EdInputSwitch,
    EdButton,
  },
  watch: {},
  mounted() {},
  created() {},
  data() {
    return {
      arrayTipoAutenticacao: [
        { intId: "tls", strNome: "TLS" },
        { intId: "ssl", strNome: "SSL" },
      ],
    };
  },
  watch: {},
  methods: {
    validarCertificado() {
      if (!this.formData.arquivo || !this.formData.strSenha) {
        this.$root.$dialog.error("Por favor selecione um certificado e digite a senha");
        return;
      }

      this.$root.$dialog.loading(true);

      let formData = Object.assign(this.formData, {});
      formData.boolValidarCertificado = true;
      formData.boolDisableJsonNumeric = true;

      this.$root.$request
        .get("Configuracoes/Certificado", formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.$root.$message.success("Certificado validado com sucesso");
            this.formData.strNome = objResponse.result.strNome;
            this.formData.strDocumento = objResponse.result.strDocumento;
            this.formData.strDataValidade = objResponse.result.strDataValidade;
          }
        });
    },
  },
};
</script>
