<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroEmpresa" width="80%" >
      <div slot="title">
        Vincular nova empresa
      </div>

      <div class="" slot="content">
        <ed-form :formData="formDataEmpresa" :handlerSave="salvar" disableFixedSave>
          <div class="row">
            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataEmpresa.intCadastroGeralEmpresaId"
              name="intCadastroGeralEmpresaId"
              item-text="strNome"
              label="Empresa"
              route="Cadastro/CadastroGeral"
              rules="required"
              @selected="(x) => {formDataEmpresa.empresa = x}"
              :filters="{ intTipoCadastroGeralId: 58 }"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @delete="onDelete"
      :config="{ registerName: 'Vincular nova empresa' }"
      disabledSearchRows
      disabledEditRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputAutoComplete,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputAutoComplete,
    EdInputSwitch,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroEmpresa: false,
      arrayHeaders: [
        {
          text: "CNPJ",
          sortable: true,
          value: "strCnpj",
        },
        {
          text: "Empresa",
          sortable: true,
          value: "strNome",
        },
      ],
      arrayRows: [],
      formDataEmpresa: {
        intId: 0,
      },
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.empresas) {
        this.formData.empresas = [];
      }

      let arrayRows = [];

      this.formData.empresas.forEach((objModel) => {
        if (!objModel.strDataExclusao) {
          let item = {
            intId: objModel.intId,
            intCadastroGeralEmpresaId: objModel.intCadastroGeralEmpresaId,
            empresa: objModel.empresa,
            strCnpj: objModel.empresa.strCnpj,
            strNome: objModel.empresa.strNome,
            _item: objModel,
          };

          arrayRows.push(item);
        }
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroEmpresa = !this.boolExibirCadastroEmpresa;

      this.formDataEmpresa = {
        intId: 0,
        intFuncionarioId: this.formData.intId,
        intCadastroGeralEmpresaId: null,
        empresa: null,
      };
    },

    onDelete(data) {
      let self = this;

      console.log('data',data)

      data.forEach((intId) => {
        let indexCadastroEmpresa = self.getIndexByIntCadastroGeralId(intId);

        if (indexCadastroEmpresa != -1) {
          self.formData.empresas.splice(indexCadastroEmpresa,1)
        }
      });

      this.initialize()
    },

    getIndexByIntId(intId) {
      return this.formData.empresas.findIndex((c) => c.intId == intId);
    },

    getIndexByIntCadastroGeralId(intCadastroGeralEmpresaId) {
      return this.formData.empresas.findIndex(
        (c) => c.intCadastroGeralEmpresaId == intCadastroGeralEmpresaId
      );
    },

    salvar() {
      let intIndexCadastroGeral = this.getIndexByIntCadastroGeralId(
        this.formDataEmpresa.intCadastroGeralEmpresaId
      );

      if (intIndexCadastroGeral != -1) {
        this.formData.empresas[intIndexCadastroGeral] = Object.assign(
          this.formDataEmpresa,
          {}
        );
      } else {
        this.intRegistroInserido--;

        this.formData.empresas.push({
          intId: this.formDataEmpresa.intCadastroGeralEmpresaId,
          intCadastroGeralEmpresaId: this.formDataEmpresa.intCadastroGeralEmpresaId,
          empresa: this.formDataEmpresa.empresa,
        });
      }

      this.initialize();
      this.boolExibirCadastroEmpresa = !this.boolExibirCadastroEmpresa;
    },
  },
};
</script>
