<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-procuracao">
        Vincular empresa por Procuração
        <i :class="$utilidade.getIcone('link') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral
        v-show="strTab == 'tab-cadastro'"
        :formData="formData"
        :key="'cadastro-' + $root.$session.versao"
      />

      <procuracao
        v-show="strTab == 'tab-procuracao'"
        :formData="formData"
        :key="'procuracao-' + $root.$session.versao"
      />
    </ed-form>
  </div>
</template>

<script>
import { EdForm, EdButton } from "@/components/common/form";

import CadastroGeral from "./partials/geral.vue";
import Procuracao from "./partials/procuracao.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intCertificadoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    Procuracao,
    EdButton,
  },
  mounted() {
    this.getCertificado();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        strNome: null,
        strEmail: null,
        strUsuario: null,
        strSenha: null,
        strEnderecoServidor: null,
        boolAutenticacao: 1,
        boolAtivo: 1,
        intPorta: 587,
        strAutenticacao: "tls",
        intTamanhoPacote: 20,
        intTempoEsperaPacote: 5,
        servicos: [],
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {
    boolEnviarEmail() {
      return this.formData.strNome &&
        this.formData.strEmail &&
        this.formData.strUsuario &&
        this.formData.strEnderecoServidor &&
        this.formData.intPorta
        ? true
        : false;
    },
  },
  methods: {
    getCertificado() {
      if (!this.intCertificadoId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Configuracoes/Certificado", { intId: this.intCertificadoId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.formData = Object.assign(objResponse.result, {});
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Configuracoes/Certificado", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            if (this.$route.name != "configuracoes.certificado.edit") {
              this.$router.push({
                name: "configuracoes.certificado.edit",
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.getCertificado();
            }
          }
        });
    },
  },
};
</script>
